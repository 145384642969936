/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Bower
@bower_path: "../../../../../../bower_components/";

// Font Awesome
@import "@{bower_path}font-awesome/less/font-awesome.less";

// Core variables and mixins
@import "@{bower_path}bootstrap/less/variables.less";
@import "@{bower_path}bootstrap/less/mixins.less";

// Reset and dependencies
@import "@{bower_path}bootstrap/less/normalize.less";
//@import "@{bower_path}bootstrap/less/print.less";
//@import "@{bower_path}bootstrap/less/glyphicons.less";

// Core CSS
@import "@{bower_path}bootstrap/less/scaffolding.less";
@import "@{bower_path}bootstrap/less/type.less";
//@import "@{bower_path}bootstrap/less/code.less";
@import "@{bower_path}bootstrap/less/grid.less";
@import "@{bower_path}bootstrap/less/tables.less";
@import "@{bower_path}bootstrap/less/forms.less";
@import "@{bower_path}bootstrap/less/buttons.less";

// Components
@import "@{bower_path}bootstrap/less/component-animations.less";
@import "@{bower_path}bootstrap/less/dropdowns.less";
@import "@{bower_path}bootstrap/less/button-groups.less";
@import "@{bower_path}bootstrap/less/input-groups.less";
@import "@{bower_path}bootstrap/less/navs.less";
@import "@{bower_path}bootstrap/less/navbar.less";
@import "@{bower_path}bootstrap/less/breadcrumbs.less";
@import "@{bower_path}bootstrap/less/pagination.less";
//@import "@{bower_path}bootstrap/less/pager.less";
//@import "@{bower_path}bootstrap/less/labels.less";
//@import "@{bower_path}bootstrap/less/badges.less";
//@import "@{bower_path}bootstrap/less/jumbotron.less";
//@import "@{bower_path}bootstrap/less/thumbnails.less";
//@import "@{bower_path}bootstrap/less/alerts.less";
//@import "@{bower_path}bootstrap/less/progress-bars.less";
//@import "@{bower_path}bootstrap/less/media.less";
//@import "@{bower_path}bootstrap/less/list-group.less";
@import "@{bower_path}bootstrap/less/panels.less";
//@import "@{bower_path}bootstrap/less/responsive-embed.less";
//@import "@{bower_path}bootstrap/less/wells.less";
//@import "@{bower_path}bootstrap/less/close.less";

// Components w/ JavaScript
@import "@{bower_path}bootstrap/less/modals.less";
//@import "@{bower_path}bootstrap/less/tooltip.less";
//@import "@{bower_path}bootstrap/less/popovers.less";
//@import "@{bower_path}bootstrap/less/carousel.less";

// Utility classes
@import "@{bower_path}bootstrap/less/utilities.less";
@import "@{bower_path}bootstrap/less/responsive-utilities.less";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Fonts
@font-family-fira-sans-ex-cond: 'Fira Sans Extra Condensed', sans-serif;
@font-family-base: @font-family-fira-sans-ex-cond;

// Colors
@brand-color: @brand-color-orange;
@brand-color-orange: #ff8941;
@brand-color-blue: #0040a1;
@brand-color-yellow: #ffcb05;
@brand-color-light-gray: #f2f2f2;
@brand-color-gray: #9b9b9b;
@brand-color-disabled: #7c7c7c;
@color-black-opacity: rgba(0, 0, 0, 0.05);

@text-color: #000000;
@link-color: @brand-color;
@font-size-base: 16px;
@line-height-base: 1.4;

//== Navbar
@navbar-border-radius: 0;
@navbar-default-bg: transparent;
@navbar-default-link-color: #000000;
@navbar-default-link-hover-color: @brand-color;
@navbar-default-link-hover-bg: transparent;

@navbar-default-link-active-color: @navbar-default-link-color;
@navbar-default-link-active-bg: @navbar-default-link-hover-bg;
@navbar-default-toggle-hover-bg: @navbar-default-link-hover-bg;
@navbar-default-toggle-icon-bar-bg: #ffffff;
@navbar-default-toggle-border-color: #ffffff;
//@nav-link-hover-bg: transparent;

//== Footer
@font-color-footer: #585858;

//== Breadcrumbs
@breadcrumb-padding-vertical:   12px;
@breadcrumb-padding-horizontal: 0;
@breadcrumb-bg:                 transparent;
@breadcrumb-color:              #797979;
@breadcrumb-active-color:       #000000;
@breadcrumb-separator:          "/";
.breadcrumb {
  margin-bottom: 20px;
  > li > a {
    color: @breadcrumb-color;
    font-size: 14px;
    font-weight: 300;
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  > li:last-child > a, > .active {
    color: @breadcrumb-active-color;
  }
}


//== Pagination
@pagination-color:                     @brand-color-gray;
@pagination-border:                    @brand-color-gray;

@pagination-hover-color:               @brand-color;
@pagination-hover-bg:                  transparent;
@pagination-hover-border:              @brand-color;

@pagination-active-color:              @brand-color;
@pagination-active-bg:                 transparent;
@pagination-active-border:             @brand-color;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               #fff;
@pagination-disabled-border:           #ddd;

.pagination {
  margin: 0 0;
  > li {
    > a,
    > span {
      .text-center;
      width: 40px;
      padding: 5px 0;
      font-size: 18px;
      border-radius: 0;
      border: 1px solid transparent;
    }
    &:first-child {
      > a,
      > span {
        .border-left-radius(0);
      }
    }
    &:last-child {
      > a,
      > span {
        .border-right-radius(0);
      }
    }
  }
}

// Helped style classes
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image > a, .block-img {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  > img {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
.mail {
  color: @link-color;
  font-size: 18px;
  text-decoration: none;
  &:hover, &:focus, &:active {
    color: @link-color;
    text-decoration: underline;
  }
}
.block-link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.link {
  color: @link-color;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: @link-color;
    text-decoration: none;
  }
}
.btn {
  padding: 10px 30px;
  border-radius: 0;
  border-width: 1px;
  &:focus, &:hover, &:active {
    box-shadow: none;
  }
}
.btn-icon {
  background-color: transparent;
  opacity: 0.7;
  .fa {
    padding-top: 3px;
    color: @brand-color;
    font-size: 22px;
    opacity: 0.7;
  }
  &:focus, &:hover, &:active {
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
  }
}
.btn-primary {
  font-size: 15px;
  color: #ffffff;
  background-color: #0040a1;
  border-color: #0040a1;
  border-radius: 0;
  &:hover, &:focus {
    color: #ffffff;
    background-color: #186be9;
    border-color: #186be9;
  }
  &:active, &:active:hover {
    color: #ffffff;
    background-color: #0040a1;
    border-color: #0040a1;
  }
}
.btn-default {
  .btn-primary;
}
.btn-info {
  color: #ffffff;
  background-color: darken(@brand-color-gray, 10%);
  border: none;
  .fa {
    color: #ffffff;
  }
  &:hover, &:focus, &:active {
    color: #ffffff;
    .fa {
      color: #ffffff;
    }
    background-color: darken(@brand-color-gray, 15%);
  }
}
.btn-modal {
  margin-bottom: 40px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 3px;
  padding-left: 0;
  color: @brand-color-blue;
  font-size: 24px;
  border-bottom: 1px dashed currentColor;
  background-color: transparent;
  &:focus, &:hover, &:active {
    color: @brand-color-blue;
    border-bottom: 1px dashed transparent;
    background-color: transparent;
    box-shadow: none;
  }
}

// Repeted style classes
.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.mail {
  display: block;
  color: @brand-color-blue;
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  &:hover, &:focus, &:active {
    color: @brand-color-blue;
    text-decoration: none;
  }
}
.social {
  > a {
    display: inline-block;
    vertical-align: top;
    width: 38px;
    height: 38px;
    margin-left: 15px;
    color: #ffffff;
    font-size: 24px;
    &:hover, &:focus {
      color: lighten(@brand-color, 50%);
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
#logo {
  .block-img {
    max-width: 100px;
  }
  > a {
    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-img {
      max-width: 160px;
    }
  }
}
.contacts-row {
  .clear;
  font-size: 15px;
  > div {
    .pull-left;
    display: block;
    height: 25px;
  }
  > p {
    .pull-left;
    display: block;
  }
  .fa {
    .text-center;
    width: 30px;
  }
  .fa:not(.fa-envelope) {
    font-size: 18px;
  }
  .fa, span {
    color: @brand-color-gray;
  }
  p {
    margin-bottom: 0;
    font-size: 15px;
  }
  .mail {
    display: inline-block;
    font-size: 15px;
  }
  &.map {
    > div {
      width: 75px;
    }
    > p {
      width: ~"calc(100% - 75px)";
    }
  }
  &.consultation {
    > div {
      width: 120px;
    }
    > p {
      width: ~"calc(100% - 120px)";
    }
  }
  &.mail {
    > div {
      width: 65px;
    }
    > p {
      width: ~"calc(100% - 65px)";
    }
  }
  &.telephone {
    > div {
      width: 100px;
    }
    p {
      width: ~"calc(100% - 100px)";
      font-size: 17px;
      font-weight: 600;
      line-height: 1.2;
    }
  }
}
.page-title {
  .text-uppercase;
  margin-bottom: 35px;
  font-size: 30px;
}
.title-block {
  color: #000000;
  font-size: 18px;
  .fa {
    color: @brand-color-gray;
    font-size: 20px;
  }
  &:focus, &:hover, &:active {
    text-decoration: none;
  }
  @media (min-width: @screen-md-min) {
    font-size: 24px;
  }
}
.social {
  > a {
    display: inline-block;
    vertical-align: top;
    width: 38px;
    height: 38px;
    margin-left: 15px;
    padding-top: 6px;
    color: #000000;
    font-size: 24px;
    &:hover, &:focus {
      color: @brand-color-gray;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.list {
  .list-unstyled;
  > li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    &:before {
      content: "\f0c8";
      position: absolute;
      top: 7px;
      left: 7px;
      font-family: FontAwesome;
      font-size: 5px;
    }
  }
}

// Grid classes
.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}
.flex-item {
  position: relative;
  display: flex;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @media (min-width: @screen-sm-min) {
    width: ~'calc((100% - 2 * 20px)/3)';
    margin-left: 20px;
    &:nth-child( 3n + 1 ) {
      margin-left: 0;
    }
  }
}

//------------------------------------------------------
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;
  .body {
    flex: 1;
  }
  &:not(.common-home).body {
    padding-bottom: 50px;
  }
}
#modalOrder input:not(.btn), footer input, #modalOrder textarea, footer textarea {
  margin-bottom: 15px;
  border-radius: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
  &:hover, &:focus, &:active {
    box-shadow: 0 0 8px rgba(233, 226, 0, 0.6) !important;
  }
}
footer, #modalOrder {
  #input-name, #input-email, #input-phone {
    border-color: #ffffff !important;
  }
}
#input-name, #input-email, #input-phone {
  max-width: 250px;
}
input:not(.search-input), textarea {
  margin-bottom: 15px;
  border-radius: 0 !important;
  border-color: @brand-color-gray !important;
  box-shadow: none !important;
  &:hover, &:focus, &:active {
    box-shadow: 0 0 8px rgba(233, 226, 0, 0.6) !important;
  }
}

@top-navbar-height-xs: 60px;
@top-navbar-height: 100px;
@top-navbar-background-color: @brand-color-light-gray;
#top {
  min-height: @top-navbar-height;
  padding-top: 5px;
  background: @top-navbar-background-color;
  .wrapper {
    .container-fluid;
  }
  .contacts-block {
    .hidden-xs;
  }
  .lang-block {
    .pull-left;
    width: 50px;
  }
  @media (min-width: @screen-sm-min) {
    padding-top: 20px;
    .wrapper {
      .clear;
    }
    .logo-block {
      .pull-left;
      width: 160px;
    }
    .contacts-block {
      .pull-left;
      .clear;
      width: ~"calc(100% - 160px - 50px)";
      padding-left: 2%;
      padding-top: 0;
      padding-bottom: 10px;
    }
    .contacts-block-col-left {
      .pull-left;
      width: 40%;
    }
    .contacts-block-col-right {
      .pull-left;
      width: 60%;
    }
    .contacts-row {
      display: inline-block;
      vertical-align: top;
      font-size: 15px;
    }
  }
  @media (min-width: @screen-md-min) {
    .wrapper {
      .container;
    }
  }
}

header {
  border-top: 1px solid lighten(@brand-color-gray, 15%);
  border-bottom: 1px solid lighten(@brand-color-gray, 15%);
  @media (min-width: @screen-sm-min) {
    border-top: none;
    border-bottom: none;
  }
  @media (min-width: @screen-lg-min) {
    .clear;
    .categories-block {
      .pull-left;
      width: ~"calc(100% - 300px)";
    }
    .search-block {
      .hidden-xs;
      .pull-right;
      width: 300px;
    }
  }
}
@menu-navbar-height-xs: 30px;
@menu-navbar-height: 75px;
#menu {
  min-height: @menu-navbar-height-xs;
  margin-bottom: 0;
  .navbar-brand {
    height: @menu-navbar-height-xs;
  }
  .navbar-header {
    .clear;
    .search-block {
      .pull-left;
      width: 47px;
      border: 1px solid @brand-color-gray;
      margin-top: 10px;
      > a {
        display: block;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 15px;
        color: @brand-color-gray;
      }
      .dropdown-menu {
        left: 18px;
      }
      &:focus, &:hover, &:active {
        border: 1px solid #000000;
        > a {
          color: #000000;
        }
      }
    }
    #category {
      .pull-left;
      .text-uppercase;
      .text-right;
      width: ~"calc(100% - 100px)";
      height: 45px;
      padding-top: 18px;
      padding-right: 8px;
      font-size: 16px;
    }
    .navbar-toggle {
      .pull-right;
      width: 50px;
      margin-right: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 15px;
      padding-left: 15px;
      border-color: @brand-color-gray;
      .fa {
        color: @brand-color-gray;
      }
      &:focus, &:hover, &:active {
        border-color: #000000;
        .fa {
          color: #000000;
        }
      }
    }
  }
  .navbar-nav > li > a {
    .text-center;
    .text-uppercase;
    height: @menu-navbar-height-xs;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
  }
  @media (min-width: @screen-sm-min) {
    min-height: @menu-navbar-height;
    .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }
    .navbar-nav {
      .align-center;
      justify-content: flex-start;
      float: none;
      > li {
        float: none;
        > a {
          .align-center;
          float: none;
          height: @menu-navbar-height;
        }
        &:first-child > a {
          padding-left: 0;
        }
      }
    }
  }
}
.search-block {
  #search {
    .align-center;
    justify-content: flex-end;
    height: @menu-navbar-height;
  }
  .dropdown-menu {
    min-width: 270px;
    height: 100%;
    top: 94%;
    left: auto;
    right: 0;
    padding-top: 0;
    border-color: transparent;
    box-shadow: none;
  }
  input {
    max-width: 230px;
    height: 40px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
    box-shadow: none;
    border-top-color: @brand-color-gray !important;
    border-left-color: @brand-color-gray !important;
    border-bottom-color: @brand-color-gray !important;
    border-right-color: transparent !important;
    border-radius: 0 !important;
    &:hover, &:focus, &:active {
      box-shadow: none;
    }
  }
  .input-group-btn {
    width: 45px;
  }
  .btn {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #ffffff;
    border-top-color: @brand-color-gray;
    border-right-color: @brand-color-gray;
    border-bottom-color: @brand-color-gray;
    border-left: none;
    .fa {
      color: @brand-color-gray;
    }
    &:hover, &:focus, &:active {
      color: @brand-color;
      background-color: #ffffff;
      border-top-color: @brand-color-gray;
      border-right-color: @brand-color-gray;
      border-bottom-color: @brand-color-gray;
      .fa {
        color: @brand-color;
      }
    }
  }
}

footer {
  background-color: @brand-color-yellow;
  .logo-menu-block {
    .logo-block {
      padding-top: 15px;
      padding-left: 10px;
    }
    .categories-block {
      #menu .navbar-nav > li {
        > a:hover, > a:focus, > a:active, .active > a {
          color: #ffffff;
        }
      }
    }
  }
  .contacts-categories-question-block {
    padding-top: 10px;
    .contacts-block {
      .contacts-row .fa, .contacts-row span {
        color: #8a8a8a;
      }
      .social {
        padding-left: 10px;
      }
      > div + div {
        padding-top: 5px;
        border-top: 1px solid @brand-color-light-gray;
      }
    }
    .categories-block {
      ul > li {
        margin-bottom: 5px;
        > a {
          .text-uppercase;
          color: #000000;
          font-size: 15px;
          &:hover, &:focus, &:active, .active {
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }
    .question-block {
      p {
        .text-uppercase;
        color: #000000;
        font-size: 17px;
      }
    }
  }
  .copyright {
    padding-top: 10px;
    color: #979797;
    font-size: 13px;
    background-color: #333333;
  }
  @media (min-width: @screen-sm-min) {
    .contacts-categories-question-block {
      .clear;
      .contacts-block {
        .pull-left;
        width: 50%;
        padding-right: 20px;
      }
      .question-block {
        .pull-left;
        width: 50%;
      }
    }
  }
  @media (min-width: @screen-md-min) {
    .contacts-categories-question-block {
      .contacts-block {
        .pull-left;
        width: 30%;
        padding-right: 20px;
      }
      .categories-block {
        .pull-left;
        width: 35%;
        padding-right: 20px;
      }
      .question-block {
        .pull-left;
        width: 35%;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .logo-menu-block {
      .clear;
      .logo-block {
        .pull-left;
        width: 120px;
      }
      .categories-block {
        .pull-left;
        width: ~"calc(100% - 160px)";
        padding-left: 5%;
      }
    }
  }
}

// -------------- home ---------------
.home {
  background-color: #f5f5f5;
  .banner-putzmeister {
    position: relative;
    margin-bottom: 3vh;
    .top {
      width: 100%;
      max-width: 527px;
    }
    @media (min-width: @screen-sm-min) {
      .top {
        position: absolute;
        top: 25%;
        width: 40%;
      }
    }
  }
  .imgcategory {
    padding-right: 10px;
    padding-left: 10px;
    @media (min-width: @screen-sm-min) {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .text-about {
    .text-center;
    width: 90%;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3vh;
    .block-img {
      max-width: 27px;
      margin-bottom: 15px;
    }
  }
  .video-promo {
    .hidden-xs;
    padding-top: 3vh;
    padding-bottom: 3vh;
    background-color: #8a8a8a;
    .video-block {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      iframe {
        width: 100%;
        height: 100%;
      }
      @media (min-width: @screen-sm-min) {
        max-width: 650px;
        height: 380px;
      }
      @media (min-width: @screen-md-min) {
        max-width: 830px;
        height: 450px;
      }
      @media (min-width: @screen-lg-min) {
        max-width: 770px;
        height: 450px;
      }
    }
  }
  .advantages {
    .text-center;
    padding-top: 30px;
    padding-bottom: 30px;
    h2 {
      .text-uppercase;
      font-size: 36px;
      font-weight: 300;
    }
    .advantages-item {
      padding-right: 5%;
      padding-left: 5%;
      font-size: 16px;
      font-weight: 300;
      .fa {
        .text-center;
        margin-bottom: 15px;
        font-size: 46px;
      }
      .title {
        .text-uppercase;
        position: relative;
        margin-bottom: 30px;
        font-size: 24px;
      }
    }
    @media (min-width: @screen-sm-min) {
      .advantages-item {
        .pull-left;
        width: 33%;
      }
    }
  }
}
.imgcategory {
  .flex-container;
  margin-bottom: 60px;
  padding-top: 30px;
  background-color: #ffffff;
  .imgcategory-item {
    .flex-item;
    position: relative;
    margin-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
    border: 1px solid @brand-color-yellow;
    .block-img {
      max-width: 365px;
    }
    h4 {
      .text-uppercase;
      position: absolute;
      top: 20px;
      left: 15px;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .imgcategory-item {
      width: ~'calc((100% - 2 * 30px)/3)';
      margin-left: 30px;
      &:nth-child( 3n + 1 ) {
        margin-left: 0;
      }
      h4 {
        font-size: 24px;
      }
    }
  }
}

// -------------- news ---------------
.news-page {
  .flex-item {
    position: relative;
    display: flex;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    > div {
      width: 100%;
    }
    @media (min-width: @screen-sm-min) {
      width: ~'calc((100% - 1 * 20px)/2)';
      margin-left: 20px;
      &:nth-child( 2n + 1 ) {
        margin-left: 0;
      }
    }
  }
}
.news-item {
  margin-bottom: 20px;
  .date {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 300;
  }
  a {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
  }
}
.news-item-page {
  .news-item-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
  }
  img {
    margin-bottom: 15px;
  }
  @media (min-width: @screen-sm-min) {
    .news-item {
      img {
        max-width: 900px;
      }
    }
  }
}

// -------------- product list -------
.category-page, .product-page {
  border-top: 1px solid @brand-color-light-gray;
}
.product-categories {
  .imgcategory {
    padding-top: 0;
  }
}
.product-item {
  margin-bottom: 20px;
  border: 1px solid #e9e9e9;
  h4 {
    .text-uppercase;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #010101;
    font-size: 16px;
  }
  &:hover, &:focus, &:active {
    border: 1px solid @brand-color-yellow;
    box-shadow: 0 6px 10px rgba(0,0,0,.175);
  }
}

.product-thumb {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.category-info {
  .hidden-xs;
  .hidden-sm;
  columns: 2;
  column-gap: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  .info-title {
    .text-uppercase;
    font-size: 25px;
    line-height: 33px;
  }
}

.product-page {
  .image-additional-block {
    .clear;
    .image-additional {
      .pull-left;
      width: 74px;
      height: 74px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  .item-info {
    ul {
      > li {
        font-size: 16px;
      }
    }
  }
  .nav-block {
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .panel + .panel {
    border-top: 1px solid @brand-color-gray;
  }
  .panel-title {
    margin-top: 11px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .nav-tabs {
    position: relative;
    border-bottom: 1px solid @brand-color-gray;
    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: -2000px;
      width: 5000px;
      height: 1px;
      background-color: @brand-color-gray;
    }
  }
  .nav-tabs > li > a {
    border-radius: 0;
  }
  .nav-tabs > li > a:hover {
    border-color: #eeeeee #eeeeee @brand-color-gray;
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: @brand-color;
    border: 1px solid @brand-color-gray;
    border-bottom-color: transparent;
  }
  .tab-content {
    padding: 30px;
  }
  #tab-specification {
    table {
      max-width: 700px;
    }
  }
  .changing-info {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
  }
}

// -------------- static pages -------
.about-page {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 23px;
  @media (min-width: @screen-sm-min) {
    columns: 2;
    column-gap: 50px;
  }
}
.accordion-block {
  .panel {
    box-shadow: none;
  }
  .panel-default {
    border-color: #fff;
    .panel-heading {
      position: relative;
      padding: 5px 20px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      &:before {
        content: "\f054";
        position: absolute;
        top: 8px;
        left: 0;
        display: block;
        color: #000;
        font-family: FontAwesome;
        font-size: 10px;
        font-weight: 300;
      }
      &:hover, &:focus, &:active {
        a {
          text-decoration: none;
        }
      }
      .panel-title.active {
        a {
          color: @brand-color;
        }
        &:before {
          content: "\f078";
          position: absolute;
          top: 12px;
          left: 0;
          display: block;
          color: #000;
          font-family: FontAwesome;
          font-size: 10px;
          font-weight: 300;
        }
      }
    }
    > .panel-heading {
      background-color: transparent;
      border-color: #fff;
    }
    > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #fff;
    }
  }
  >.panel-group>.panel-default>.panel-heading, > .panel-group > .panel-default > .panel-collapse > .panel-body > .panel-group > .panel-default > .panel-heading {
    .text-uppercase;
  }
  .accordion-ul {
    > li > a {
      color: @brand-color-blue;
    }
  }
}
.contacts-page {
  .contacts-block {
    margin-bottom: 2vh;
  }
  .contacts-row {
    p {
      font-size: 18px;
      font-weight: 300;
    }
    .mail {
      font-size: 18px;
      font-weight: 600;
    }
  }
  @media (min-width: @screen-sm-min) {
    .contacts-block {
      .clear;
      > div {
        .pull-left;
        width: 50%;
      }
    }
  }
}
.map-block {
  height: 250px;
  margin-bottom: 5vh;
  .map {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: @screen-md-min) {
    height: 600px;
  }
}




// -------------- 404 ----------------
.page-404 {
  .text-center;
  position: relative;
  width: 100%;
  height: 100vh;
  .wrapper {
    position: relative;
    z-index: 2;
    width: 80%;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  #logo {
    margin-bottom: 8vh;
  }
  .title {
    .text-uppercase;
    font-size: 30px;
  }
  .number-404 {
    font-size: 70px;
    line-height: 0.8;
  }
  .text-404 {
    margin-bottom: 7vh;
    font-size: 22px;
  }
  @media (min-width: @screen-sm-min) {
    .title {
      font-size: 40px;
    }
    .number-404 {
      font-size: 140px;
    }
    .text-404 {
      font-size: 30px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .wrapper {
      padding-top: 5vh;
    }
  }
}

//--------------------------------- modal
.icon-close {
  margin-top: 0;
  margin-left: 0;
  width: 25px;
  height: 25px;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: currentColor;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 25px;
    height: 2px;
    background-color: currentColor;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.modal {
  .modal-dialog {
    margin-top: 10%;
  }
  .modal-body {
    padding: 0;
  }
  .modal-content {
    border-radius: 0;
  }
  .close {
    position: absolute;
    right: 8px;
    top: 2px;
    min-width: 20px;
    min-height: 20px;
    z-index: 1;
    opacity: 0.8;
    color: #000000;
    border-color: transparent;
    background-color: transparent;
  }
}
#modalOrder {
  .modal-title {
    .text-center;
    display: block;
    padding-top: 20px;
    font-size: 28px;
    letter-spacing: 5px;
  }
  .order-product {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .order-info {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: @brand-color-light-gray;
  }
  .content-success {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    p {
      .text-center;
      display: block;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      padding-left: 20px;
      font-size: 18px;
      color: black;
      background: #ffffff;
    }
    &:after {
      content: "";
      position: absolute;
      top: 70px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #000000;
      opacity: 0.3;
    }
  }
  .content-loading {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('/catalog/view/theme/kotlyhardy/image/AjaxLoader.gif') center center no-repeat;
  }
}
